import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ComingSoonPage from './ComingSoonPage'; // Assuming you have a ComingSoonPage component

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ComingSoonPage />} />
      <Route path="*" element={<ComingSoonPage />} />
    </Routes>
  );
};

const RoutesComponent = () => {
  return (
    <Router basename='/'>
      <AppRoutes />
    </Router>
  );
};

export default RoutesComponent;
