import React from 'react';
import RoutesComponent from './RoutesComponent';

function App() {
  return (
    <RoutesComponent />
  );
}

export default App;
