import React from 'react';
import './App.css'; // Import CSS for styling

// Define functional components for the Coming Soon and Welcome sections
const ComingSoon = () => {
    return (
        <div className="section">
            <h1 className="coming-soon">Coming Soon</h1>
            <p>Your one-stop destination for all your needs.</p>
        </div>
    );
};

// Main App component
function ComingSoonPage() {
    return (
        <div className="container">
            <ComingSoon />
        </div>
    );
}

export default ComingSoonPage;
